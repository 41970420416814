import { createApi } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';

import { pickAndRename } from 'utils/objectUtils';

import getCustomBaseQuery from '../customBaseQuery';
import { BE_TO_FE_INTERFACES, FE_TO_BE_INTERFACES } from './interfaces';

const attributesAdapter = createEntityAdapter({
  selectId: attribute => attribute.uuid,
});
const optionsAdapter = createEntityAdapter({
  selectId: option => option.uuid,
});
const attributesInitialState = attributesAdapter.getInitialState();
const optionsInitialState = optionsAdapter.getInitialState();

export const attributesApi = createApi({
  reducerPath: 'attributesApi',
  keepUnusedDataFor: 60 * 5,
  baseQuery: getCustomBaseQuery(),
  tagTypes: ['Attributes', 'Options'],
  endpoints: builder => ({
    getAttributes: builder.query({
      query: () => ({ url: '/attributes' }),
      providesTags: ['Attributes'],
      transformResponse: response => attributesAdapter.setAll(
        attributesInitialState,
        response
          .data
          .map(attribute => pickAndRename(attribute, BE_TO_FE_INTERFACES.Attribute)),
      ),
    }),
    createAttribute: builder.mutation({
      query: newAttribute => ({
        url: '/attributes',
        method: 'POST',
        data: pickAndRename(newAttribute, FE_TO_BE_INTERFACES.Attribute),
      }),
      invalidatesTags: ['Attributes'],
    }),
    updateAttribute: builder.mutation({
      query: ({ attributeId, ...rest }) => ({
        url: `/attributes/${attributeId}`,
        method: 'PUT',
        data: rest,
      }),
      invalidatesTags: (result, error, { attributeId }) => [
        { type: 'Attributes' },
        { type: 'Options', id: attributeId },
      ],
    }),
    deleteAttribute: builder.mutation({
      query: attributeId => ({
        url: `/attributes/${attributeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Attributes'],
    }),
    getAttributeOptions: builder.query({
      query: attributeId => ({ url: `/attributes/${attributeId}` }),
      transformResponse: response => optionsAdapter.setAll(
        optionsInitialState,
        response.data.options.map(option => pickAndRename(option, BE_TO_FE_INTERFACES.Option)),
      ),
      providesTags: (result, error, attributeId) => [{ type: 'Options', id: attributeId }],
    }),
    exportAttributes: builder.mutation({
      query: ({ attributesUUID }) => ({
        url: `/attributes/export`,
        method: 'POST',
        data: attributesUUID,
      }),
    }),
  }),
});

export const selectAttributesResult = state => state.attributesApi.queries['getAttributes(undefined)']?.data || attributesAdapter.getInitialState();

export const {
  selectAll: selectAllAttributesSelector,
  selectById: selectAttributeByIdSelector,
  selectEntities: selectAllAttributesEntitiesSelector,
} = attributesAdapter.getSelectors(state => selectAttributesResult(state));

const selectOptionsResult = state => state.attributesApi.queries;
export const selectOptionsForAttributeSelector = createSelector(
  selectOptionsResult,
  (_, attributeId) => attributeId,
  (queries, attributeId) => {
    const key = `getAttributeOptions("${attributeId}")`;

    return queries[key]?.data?.entities || {};
  },
);

export const selectAttributeOptionsMap = createSelector(
  selectOptionsResult,
  (queries) => {
    const attributeOptionsMap = {};

    Object.keys(queries).forEach((key) => {
      const match = key.match(/getAttributeOptions\("([^"]+)"\)/);

      if (match) {
        const [, attributeId] = match;
        attributeOptionsMap[attributeId] = Object.values(queries[key]?.data?.entities || {});
      }
    });

    return attributeOptionsMap;
  },
);

export const {
  useGetAttributesQuery,
  useGetAttributeOptionsQuery,
  useLazyGetAttributeOptionsQuery,
  useCreateAttributeMutation,
  useUpdateAttributeMutation,
  useDeleteAttributeMutation,
  useExportAttributesMutation,
} = attributesApi;
