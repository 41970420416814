import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import getParams from 'utils/getParams';

import config from './config';

const { auth0Domain, auth0ClientID } = config;

const Auth0ProviderWrapper = ({ children, organizationId }) => {
  const navigate = useNavigate();
  const { blink_id = null } = getParams();
  const state = blink_id ? `?blink_id=${blink_id}` : '';
  const windowPath = window.location.pathname !== '/' ? window.location.pathname : config.defaultPath;
  const onRedirectCallback = (appState = {}) => {
    if (appState.redirectTo) {
      navigate(appState.redirectTo);
    }
  };

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientID}
      authorizationParams={{
        redirect_uri: window.location.origin + windowPath + state,
        ...(organizationId && { organization: organizationId }),
      }}
      useRefreshTokens
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWrapper;
