import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from './customBaseQuery';

export const analyticsApi = createApi({
  reducerPath: 'analyticsApi',
  keepUnusedDataFor: 60 * 5,
  baseQuery: async (args, api, extraOptions) => {
    const state = api.getState();
    const { domainID = '' } = state.auth;

    const { data: { data: { token } } = {} } = await customBaseQuery()({
      url: `/${domainID}/links/list-ids`,
      method: 'GET',
      params: args.params,
    });

    args.params = { ...args.params, token };

    return await customBaseQuery()(args, api, extraOptions);
  },
  endpoints: builder => ({
    getOverviewChartData: builder.query({
      query: ({ timeline, domainId, queryParams }) => ({
        url: `/${domainId}/stats/charts/${timeline}`,
        method: 'GET',
        params: queryParams,
      }),
      transformResponse: response => response.data,
    }),
    getAnalyticsWidgetData: builder.query({
      query: ({ domainId, queryParams }) => ({
        url: `/${domainId}/stats/main`,
        method: 'GET',
        params: queryParams,
      }),
      transformResponse: response => response.data,
    }),
    getAnalyticsOSData: builder.query({
      query: ({ domainId, queryParams }) => ({
        url: `/${domainId}/stats/os/chart`,
        method: 'GET',
        params: queryParams,
      }),
      transformResponse: response => response.data,
    }),
    getAnalyticsDeviceData: builder.query({
      query: ({ domainId, queryParams }) => ({
        url: `/${domainId}/stats/device/chart`,
        method: 'GET',
        params: queryParams,
      }),
      transformResponse: response => response.data,
    }),
    getReferrersChartData: builder.query({
      query: ({ domainId, queryParams }) => ({
        url: `/${domainId}/stats/referrer/chart`,
        method: 'GET',
        params: queryParams,
      }),
      transformResponse: response => response.data,
    }),
    getTopCountriesData: builder.query({
      query: ({ domainId, queryParams }) => ({
        url: `/${domainId}/stats/location/top-country`,
        method: 'GET',
        params: queryParams,
      }),
      transformResponse: response => response.data,
    }),
    getTopCitiesData: builder.query({
      query: ({ domainId, queryParams }) => ({
        url: `/${domainId}/stats/location/top-city`,
        method: 'GET',
        params: queryParams,
      }),
      transformResponse: response => response.data,
    }),
  }),
});

export const {
  useLazyGetNewTokenQuery,
  useGetOverviewChartDataQuery,
  useGetAnalyticsWidgetDataQuery,
  useGetTopAnalyticsDataQuery,
  useGetAnalyticsOSDataQuery,
  useGetAnalyticsDeviceDataQuery,
  useGetReferrersChartDataQuery,
  useGetTopCountriesDataQuery,
  useGetTopCitiesDataQuery,
  useLazyGetTopCountriesDataQuery,
  useLazyGetTopCitiesDataQuery,
} = analyticsApi;
