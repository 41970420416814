export default function InputBase() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState: { required } }) => ({
          '& .MuiOutlinedInput-root': {
            ...(required && { backgroundColor: 'rgba(69, 63, 145, 0.1)' }),
            borderRadius: '8px',
            '& fieldset': {
              borderColor: '#D9D9D9 !important',
            },
            '&:hover fieldset': {
              borderColor: '#D9D9D9 !important',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#D9D9D9 !important',
            },
            '&.Mui-focused': {
              boxShadow: 'none'
            },
            '&.Mui-error fieldset': {
              borderColor: '#FF4D4F !important',
            },
            '&.Mui-error:hover fieldset': {
              borderColor: '#FF4D4F !important',
            },
            '&.Mui-disabled': {
              '& fieldset': {
                borderColor: '#D9D9D9 !important',
                backgroundColor: 'rgba(232, 233, 236, .6)',
              },
              '& .MuiOutlinedInput-input': {
                color: '#605E70',
                WebkitTextFillColor: '#605E70',
              },
            },
            '&.MuiInputBase-multiline': {
              padding: '0 !important'
            },
          },
          '& .MuiOutlinedInput-input': {
            padding: '9px 12px',
            fontSize: '15px'
          },
        })
      },
    },

    // FIXME: remove it when remove CustomFormItem at all
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          '& .MuiInputBase-input': {
            color: '#453F91!important',
            borderColor: '#453F91!important'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#453F91!important'
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#D9D9D9 !important',
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              color: '#9A99A9'
            }
          },
        },
        sizeSmall: {
          fontSize: '0.75rem'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            marginBottom: 0
          }
        }
      }
    }

  };
}
