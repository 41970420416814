import { useSelector } from '../store';
import { authSelector } from 'selectors/auth.js';

const usePermissions = () => {
  const { role, roles, permissions, userRoleType, user } = useSelector(authSelector || {});

  return {
    role,
    roles,
    permissions,
    userRoleType,
    currentUser: user,
  };
};

export default usePermissions;
