import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { dispatch, useSelector } from 'store';
import { setAccessToken, setUserData, setUserDomain } from 'store/reducers/auth';
import { getMyProfileData, getMyUserData } from '_api/account';

import RedirectHandler from '../layout/MainLayout/RedirectHandler';
import { authSelector } from 'selectors/auth.js';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { role } = useSelector(authSelector || {});
  const location = useLocation();
  const navigate = useNavigate();
  const domainID = localStorage.getItem('domainID');
  const userRoleDomainID = localStorage.getItem('userRoleDomainID');

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated && !isLoading) {
          const { id_token } = await getAccessTokenSilently({
            scope: 'openid email profile',
            detailedResponse: true,
            ignoreCache: true,
            cacheMode: 'off',
          });
          const tokenObj = { accessToken: id_token, tokenType: 'Bearer' };

          localStorage.setItem('userData', JSON.stringify(tokenObj));
          dispatch(setAccessToken({ accessToken: id_token }));
          // If there is no current user, but we have a token get the user date from the API
          dispatch(setUserData({ ...tokenObj, domainID }));
          dispatch(getMyUserData());
          dispatch(getMyProfileData());

          if (domainID) {
            dispatch(setUserDomain({ domainID, userRoleDomainID }));
          }

          const searchParams = new URLSearchParams(location.search);
          searchParams.delete('code');
          searchParams.delete('state');
          const newSearch = searchParams.toString();
          navigate({ pathname: location.pathname, search: newSearch });
        }

        if (!isLoading && !isAuthenticated) {
          await loginWithRedirect();
        }
      } catch (error) {
        await loginWithRedirect();
      }
    })();
    //  eslint-disable-next-line
    }, [isAuthenticated, isLoading]);

  return <>{role && <RedirectHandler>{children}</RedirectHandler>}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
