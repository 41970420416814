import _ from 'lodash';
import * as yup from 'yup';
import get from 'lodash/get';
import set from 'lodash/set';
import { fieldTypes } from 'layout/CommonLayout/CustomFormItem/constants';

// Use _.mapValues() to append new fields to each property of the object
export const appendNewFieldsObj = (originalObject, options) =>
  _.mapValues(
    _.omitBy(originalObject, value => value === null),
    (value, key) => {
      if (value === null) {
        delete originalObject[key];
        return null;
      }
      return {
        value,
        options,
      };
    },
  );

export const removeEmptyValues = obj => (obj
  ? Object.fromEntries(
    Object.entries(obj)
      .filter(([, value]) => Boolean(value))
      .map(([key, value]) => [key, value === Object(value) ? removeEmptyValues(value) : value]),
  )
  : {});

export const updateObjKey = (obj, newKeyMap) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeyMap[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

const shouldCollect = s => _.negate(_.overSome([_.isUndefined, _.isObject, _.partial(_.eq, s)]));

export const getYupValidationForObjects = (objectList) => {
  const validationObject = {};

  objectList.forEach(({ code, field_type }) => {
    switch (field_type) {
      case fieldTypes.CHECKBOX:
        validationObject[code] = yup.mixed().test('checkbox-validator', function(value) {
          if (!value || !value?.length) {
            return this.createError({
              path: this.path,
              message: 'This field is required',
            });
          } else {
            return true;
          }
        });
        break;
      default:
        validationObject[code] = yup
          .string('This field is required')
          .nullable()
          .required('This field is required');
        break;
    }
  });

  return validationObject;
};

export const toBase64 = blob => new Promise((resolve) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.readAsDataURL(blob);
});

export const flipObject = (obj) => {
  const flipped = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      flipped[obj[key]] = key;
    }
  }
  return flipped;
};

export const pickAndRename = (object, mapping) => {
  const result = {};

  Object.entries(mapping).forEach(([key, value]) => {
    if (typeof value === 'object') {
      if (Array.isArray(get(object, key))) {
        return set(result, value.name, get(object, key, []).map(item => pickAndRename(item, value.transform)));
      }

      return set(result, value.name, pickAndRename(get(object, key), value.transform));
    }

    return set(result, value, get(object, key));
  });

  return result;
};
