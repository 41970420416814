import Pusher from 'pusher-js';
import config from 'config';

const { pusherKey, pusherCluster } = config;

// Enable pusher logging - don't include this in production
Pusher.logToConsole = true;
  
const pusher = new Pusher(pusherKey, {
  cluster: pusherCluster,
});

export default pusher;
