import { createContext, useEffect } from 'react';
import {
  featureSelector,
  hasObservePointFeatureSelector,
  hasTrustedDestinationSelector,
  hasPowerPagesSelector,
  hasELinksSelector,
  isApproveRequiredSelector,
} from 'store/selectors/feature';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { getFeatures } from '_api/features';
import { authSelector } from 'selectors/auth.js';

const defaultState = {
  features: [],
  loading: true,
  error: null,
};

const FeatureContext = createContext(defaultState);

const FeatureProvider = ({ children }) => {
  const { features, getFeaturesLoading } = useSelector(featureSelector);
  const { accessToken = '', domainID = '' } = useSelector(authSelector);
  const hasObservePointFeature = useSelector(hasObservePointFeatureSelector);
  const hasTrustedDestinationFeature = useSelector(hasTrustedDestinationSelector);
  const hasELinksFeature = useSelector(hasELinksSelector);
  const hasPowerPageFeature = useSelector(hasPowerPagesSelector);
  const isApproveRequiredFeature = useSelector(isApproveRequiredSelector);

  useEffect(() => {
    if (!features && accessToken && domainID) {
      dispatch(getFeatures());
    }
  }, [features, accessToken, domainID]);

  return (
    <FeatureContext.Provider
      value={{
        features,
        loading: getFeaturesLoading,
        hasTrustedDestinationFeature,
        hasObservePointFeature,
        hasPowerPageFeature,
        hasELinksFeature,
        isApproveRequiredFeature,
      }}
    >
      {children}
    </FeatureContext.Provider>
  );
};

export { FeatureContext, FeatureProvider };
