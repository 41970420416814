import { createSelector } from '@reduxjs/toolkit';

export const authSelector = state => state.auth;

export const domainUUIDSelector = createSelector(
  authSelector,
  ({ domainID }) => domainID,
);

export const userTimeZoneSelector = createSelector(
  authSelector,
  ({ userProfile }) => userProfile?.timezone || '',
);
