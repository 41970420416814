export const featuresCods = {
  TRUSTED_DESTINATIONS: 'trusted-destinations',
  PENDING_LINKS: 'pending-links',
  E_LINKS: 'e-link',
  OBSERVE_POINT: 'observepoint',
  POWER_PAGES: 'power-pages',
  NO_BLANK_LINK_TEMPLATE: 'no-blank-link-template',
};

export default featuresCods;
