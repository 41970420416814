import { createSlice } from '@reduxjs/toolkit';
import { startOfMonth, startOfToday, subDays } from 'date-fns';

import { DateRanges } from 'components/Analytics/constants';

const today = startOfToday();
const rangeToDate = {
  [DateRanges.Yesterday]: [subDays(today, 1), subDays(today, 1)],
  [DateRanges.Today]: [today, today],
  [DateRanges.Days_7]: [subDays(today, 6), today],
  [DateRanges.Days_30]: [subDays(today, 29), today],
  [DateRanges.Month]: [startOfMonth(today), today],
  [DateRanges.Custom]: [today, today],
};

const initialState = {
  selectedRange: DateRanges.Days_7,
  get startDate() {
    return rangeToDate[this.selectedRange][0];
  },
  get endDate() {
    return rangeToDate[this.selectedRange][1];
  },
};

export const analytics = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setSelectedRange: (state, { payload }) => {
      state.selectedRange = payload.range;

      if (payload.range === DateRanges.CustomRange) {
        state.startDate = payload.startDate;
        state.endDate = payload.endDate;
      } else {
        [state.startDate, state.endDate] = rangeToDate[payload.range];
      }
    },
  },
  extraReducers: {},
});

export const { setSelectedRange } = analytics.actions;

export default analytics.reducer;
