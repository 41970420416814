export const transformAssignedAttributes = data => data.reduce((acc, item) => {
  const { uuid, name, code, selected, meta } = item;

  if (!acc[uuid]) {
    acc[uuid] = {
      uuid,
      name,
      code,
      options: [],
      selected: [],
      values: [],
      meta,
    };
  }

  if (selected.key !== null) {
    acc[uuid].selected.push(selected.key);
  } else {
    acc[uuid].selected = selected.value;
  }

  acc[uuid].values.push(selected.value);

  acc[uuid].options.push({
    value: selected.key,
    label: selected.value,
  });

  return acc;
}, {});
