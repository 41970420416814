import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

import conditionalAttributesMocks from './attributes';

const statusToMethod = {
  GET: 'onGet',
  POST: 'onPost',
  PUT: 'onPut',
  DELETE: 'onDelete',
};

const mockInstance = new MockAdapter(axios);

[conditionalAttributesMocks].forEach(
  type => type.forEach(
    mock => mockInstance[statusToMethod[mock.method]](mock.endpoint).reply(
      mock.response.status, mock.response.body,
    ),
  ),
);

export default axios;
