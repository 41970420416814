import { lazy } from 'react';

import AuthGuard from 'middleware/AuthGuard';
import MainLayout from 'layout/MainLayout';
import loadable from 'components/Loadable';

const PowerPagePreview = loadable(lazy(() => import('pages/admin/PowerPages/PowerPagePreview')));
const PowerPageTemplates = loadable(lazy(() => import('pages/admin/PowerPages/PowerPageTemplates')));

const PowerPageRoutes = {
  path: '',
  children: [
    {
      path: '/power-pages',
      element: (
        <AuthGuard>
          <MainLayout>
            <PowerPageTemplates />
          </MainLayout>
        </AuthGuard>
      ),
    },
    {
      path: '/power-pages/add',
      element: (
        <AuthGuard>
          <PowerPagePreview />
        </AuthGuard>
      ),
    },
    {
      path: '/power-pages/:uuid/edit',
      element: (
        <AuthGuard>
          <PowerPagePreview />
        </AuthGuard>
      ),
    },
  ],
};

export default PowerPageRoutes;
