import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getTenantsList = createAsyncThunk('tenants/getTenantsList', async ({ userUuid }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/users/${userUuid}/tenants`);

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const updateTenant = createAsyncThunk(
  'tenants/updateTenant',
  async ({ tenantId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`/tenants/${tenantId}`, data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
