import { CalendarOutlined } from '@ant-design/icons';

export const DateRanges = {
  Today: 'today',
  Yesterday: 'yesterday',
  Days_7: '7_days',
  Days_30: '30_days',
  Month: 'month',
  Custom: 'custom',
  CustomRange: 'customRange',
};

export const dateRangesOptions = [
  { value: DateRanges.Today, label: 'Today' },
  { value: DateRanges.Yesterday, label: 'Yesterday' },
  { value: DateRanges.Days_7, label: 'Last 7 days' },
  { value: DateRanges.Days_30, label: 'Last 30 days' },
  { value: DateRanges.Month, label: 'This Month' },
  { value: DateRanges.Custom, label: 'Custom', icon: <CalendarOutlined style={{ marginRight: '4px' }} /> },
];
