import { createSlice } from '@reduxjs/toolkit';
import { XHR_STATUS } from 'constants/xhr-status';
import { defaultPagination } from 'constants/pagination';
import { getPowerPagesList, getSinglePowerPage, getManagePowerPageOptions, getBlocks } from '_api/admin/power-pages';

const initialState = {
  powerPagesList: [],
  powerPageOptions: {
    socialMedia: [{ code: 'Instagram', name: 'Instagram', id: 'instagram' }, { code: 'Facebook', name: 'Facebook', id: 'facebook' }],
  },
  singlePowerPageData: {},
  blocks: [],

  powerPagesListMeta: {
    page: defaultPagination.page,
    per_page: defaultPagination.perPage,
    total: defaultPagination.total,
  },

  blocksListStatus: XHR_STATUS.idle,
  powerPagesListStatus: XHR_STATUS.idle,
  singlePowerPageStatus: XHR_STATUS.idle,
};

const powerPages = createSlice({
  name: 'powerPages',
  initialState,
  reducers: {
    resetPPListData: () => ({
      ...initialState,
    }),
    resetSinglePowerPageData: state => ({
      ...state,
      singlePowerPageData: {},
    }),
    setFirstBlock: (state, action) => ({
      ...state,
      blocks: [
        action.payload,
      ],
    }),
  },
  extraReducers: {
    // Get List of PowerPages
    [getPowerPagesList.pending]: state => ({
      ...state,
      powerPagesListStatus: XHR_STATUS.pending,
    }),

    [getPowerPagesList.fulfilled]: (state, action) => {
      const {
        data: powerPagesList = [],
        meta: powerPagesListMeta = {},
        isLazyLoad = false,
      } = action.payload ?? {};

      return {
        ...state,
        powerPagesListMeta,
        powerPagesList: isLazyLoad ? [...state.powerPagesList, ...powerPagesList] : powerPagesList,
        powerPagesListStatus: XHR_STATUS.fulfilled,
      };
    },

    [getPowerPagesList.rejected]: state => ({
      ...state,
      powerPagesListStatus: XHR_STATUS.rejected,
    }),

    // Get single PowerPage

    [getSinglePowerPage.pending]: state => ({
      ...state,
      singlePowerPageStatus: XHR_STATUS.pending,
    }),

    [getSinglePowerPage.fulfilled]: (state, actions) => {
      const { data } = actions.payload;
      return {
        ...state,
        singlePowerPageData: data,
        singlePowerPageStatus: XHR_STATUS.fulfilled,
      };
    },

    [getSinglePowerPage.rejected]: state => ({
      ...state,
      singlePowerPageStatus: XHR_STATUS.rejected,
    }),

    [getSinglePowerPage.pending]: state => ({
      ...state,
      singlePowerPageStatus: XHR_STATUS.pending,
    }),

    [getSinglePowerPage.fulfilled]: (state, actions) => {
      const { data } = actions.payload;
      return {
        ...state,
        singlePowerPageData: data,
        singlePowerPageStatus: XHR_STATUS.fulfilled,
      };
    },

    [getSinglePowerPage.rejected]: state => ({
      ...state,
      singlePowerPageStatus: XHR_STATUS.rejected,
    }),

    [getBlocks.pending]: state => ({
      ...state,
      blocksListStatus: XHR_STATUS.pending,
    }),

    [getBlocks.fulfilled]: (state, actions) => {
      const { data } = actions.payload;
      return {
        ...state,
        blocks: data,
        blocksListStatus: XHR_STATUS.fulfilled,
      };
    },

    [getBlocks.rejected]: state => ({
      ...state,
      blocksListStatus: XHR_STATUS.rejected,
    }),

    // Get Power Page options

    [getManagePowerPageOptions.fulfilled]: (state, actions) => {
      const { data } = actions.payload;
      return {
        ...state,
        powerPageOptions: data,
      };
    },
  },
});

export default powerPages.reducer;

export const {
  resetPPListData,
  resetSinglePowerPageData,
  setFirstBlock,
} = powerPages.actions;
