import get from 'lodash/get';

import { ENDPOINTS } from './constants';
import { operatorsWithoutValue } from '../../utils/dataGrid';
import { cloneDeep } from 'lodash';
import { removeEmptyValues } from '../../utils/objectUtils';

class Groups {
  constructor(restService) {
    this.restService = restService;
  }

  async getAll(data) {
    try {
      const apiFilters = Array.isArray(data.filters) ? cloneDeep(data.filters) : [];
      const params = {
        ...data,
        filters: apiFilters.filter(
          ({ value, operator }) => operatorsWithoutValue.includes(operator) ||
            (!Array.isArray(value) && value) ||
            (Array.isArray(value) && value.length),
        ),
      };
      if (!Boolean(apiFilters.length)) {
        delete params.filterLink;
      }

      return await this.restService.get(
        `${ENDPOINTS.groups}`,
        {
          params: removeEmptyValues(params),
        },
      );
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async getOne(uuid) {
    try {
      const { data } = await this.restService.get(
        `${ENDPOINTS.groups}/${uuid}`,
      );

      return data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async getAllRoles() {
    try {
      const { data } = await this.restService.get(
        `${ENDPOINTS.groupRoles}`,
      );

      return data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async getGroupUsers(uuid) {
    try {
      const { data } = await this.restService.get(
        `${ENDPOINTS.groups}/${uuid}${ENDPOINTS.users}`,
      );
      return data;
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async createGroup(data) {
    try {
      return await this.restService.post(
        ENDPOINTS.groups,
        data,
      );
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async updateGroup(data) {
    try {
      return await this.restService.patch(
        `${ENDPOINTS.groups}/${data.uuid}`,
        data,
      );
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async deleteOne(uuid) {
    try {
      await this.restService.delete(`${ENDPOINTS.groups}/${uuid}`);
    } catch (error) {
      return this.#handleError(error);
    }
  }

  async bulkDelete(uuids) {
    try {
      await this.restService.post(`${ENDPOINTS.bulkDelete}`, {
        uuids,
      });
    } catch (error) {
      return this.#handleError(error);
    }
  }

  #handleError(error) {
    return Promise.reject({ code: get(error, 'response.status'), message:  get(error, 'response.data.message') });
  }
}

export default Groups;
