export const BE_TO_FE_INTERFACES = {
  Attribute: {
    id: 'id',
    active: 'active',
    uuid: 'uuid',
    name: 'name',
    code: 'code',
    type: 'type',
    field_type: 'fieldType',
    additional_notes: 'notes', // FIXME
    description: 'description',
    order: 'order',
    templates: 'templates', // FIXME
    created_at: 'createdAt', // uses on Attributes table
    updated_at: 'updatedAt', // uses on Attributes table
    author: {
      name: 'author',
      transform: {
        id: 'id',
        first_name: 'firstName',
        last_name: 'lastName',
      },
    }, // uses on Attributes table
  },
  Option: {
    code: 'value',
    name: 'label',
    order: 'order',
    uuid: 'uuid',
  },
};

export const FE_TO_BE_INTERFACES = {
  Attribute: {
    fieldType: 'field_type',
    data_type: 'data_type',
    description: 'description',
    name: 'name',
    options: 'options',
  },
  UpdateAttribute: {
    uuid: 'uuid',
    id: 'id',
    name: 'name',
    code: 'code',
    order: 'order',
    additional: 'additional',
    description: 'description',
    field_type: 'field_type',
    data_type: 'data_type',
    type: 'type',
    active: 'active',
    created_at: 'created_at',
    updated_at: 'updated_at',
    account: 'account',
    templates: 'templates',
    fieldType: 'field_type',
    dataType: 'data_type',
    author: 'author',
    options: {
      name: 'options',
      transform: {
        key: 'code',
        value: 'name',
        order: 'order',
        uuid: 'uuid',
      },
    },
  },
  Option: {
    code: 'code',
    name: 'name',
    order: 'order',
    uuid: 'uuid',
  },
};
