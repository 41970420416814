import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { removeEmptyValues } from 'utils/objectUtils';

export const getDestinationsList = createAsyncThunk(
  'trustedDestinations/getDestinationsList',
  async (
    {
      currentPage = 0,
      currentRowCount = 25,
      currentSearchValue = '',
      currentFilters = [],
      currentFilterLink = 'and',
      currentSortData: { field: sort = 'created_at', sort: sort_direction = 'desc' } = {},
      isLazyLoad = false,
    },
    { rejectWithValue },
  ) => {
    try {
      const queryParams = {
        page: Number(currentPage),
        per_page: Number(currentRowCount),
        search: currentSearchValue,
        filters: currentFilters,
        filter_link: currentFilterLink,
        sort,
        sort_direction,
      };

      const response = await axiosInstance.get(`/destinations`, {
        params: removeEmptyValues(queryParams),
        data: {},
      });

      return { ...response.data, isLazyLoad };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const submitTrustedDestination = createAsyncThunk(
  'trustedDestinations/submitTrustedDestination',
  async ({ templateData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/destinations`, templateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteTrustedDestination = createAsyncThunk(
  'trustedDestinations/deleteTrustedDestination',
  async ({ tdId = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/destinations/${tdId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTrustedDestinationStatistics = createAsyncThunk(
  'ipExclusions/getTrustedDestinationStatistics',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/destinations/statistics');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTrustedDestinationById = createAsyncThunk(
  'trustedDestinations/getTrustedDestinationById',
  async ({ tdId = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/destinations/${tdId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateTrustedDestinationById = createAsyncThunk(
  'trustedDestinations/updateTrustedDestinationById',
  async ({ tdId = '', tdData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`/destinations/${tdId}`, tdData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getELinkTemplates = createAsyncThunk(
  'trustedDestinations/getELinkTemplates',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/destinations/e-link-pages');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTrustedDomains = createAsyncThunk(
  'trustedDestinations/getTrustedDomains',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/destinations/domains');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const checkLongURLs = createAsyncThunk(
  'trustedDestinations/checkLongURLs',
  async ({ longURLs }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();
      const response = await axiosInstance.post(`/destinations/${domainID}/rules-check`, longURLs);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const isURLTrusted = (domainUUID, longURL) =>
  axiosInstance.post(`/destinations/${domainUUID}/check`, { long_url: longURL }).then(res => res.data);

export const checkELinkLongURL = (longURL, domainID, elinkPageUUID) =>
  axiosInstance.post(`external/destination/elink-page/check/${elinkPageUUID}/${domainID}`, {
    long_url: longURL,
  }).then(res => res.data);

export const getELinkTrustedDestinations = createAsyncThunk(
  'trustedDestinations/getELinkTrustedDestinations',
  async ({ search, elinkPageUUID, domainID, page, perPage }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`external/destinations/${elinkPageUUID}/${domainID}`, {
        params: {
          page,
          per_page: perPage,
          search,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
