import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantsList } from '_api/tenants';

import { Box } from '@mui/material';

import SidebarDrawer from './SidebarDrawer';
import Header from './Header';
import useConfig from 'hooks/useConfig';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { openDrawer } from 'store/reducers/menu';
import { authSelector } from 'selectors/auth.js';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ({ children }) => {
  const { container } = useConfig();
  const dispatch = useDispatch();
  const { accessToken = '', user } = useSelector(authSelector || {});
  const [open, setOpen] = useState(JSON.parse(localStorage.getItem('sidebarOpen')) ?? false);
  const [breadcrumbsOverride, setBreadcrumbsOverride] = useState({});

  useEffect(() => {
    dispatch(openDrawer({ drawerOpen: open }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // SidebarDrawer toggle
  const handleDrawerToggle = () => {
    localStorage.setItem('sidebarOpen', JSON.stringify(!open));
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
    // TODO:: FIX ME. Set transitionEnd handler
    setTimeout(() => {
      handleTransitionEnd();
    }, 500);
  };

  const handleTransitionEnd = () => {
    window.dispatchEvent(new Event('resize'));
  };

  useEffect(() => {
    accessToken && user.uuid && dispatch(getTenantsList({ userUuid: user.uuid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100vh',
      }}
    >
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <SidebarDrawer
        handleTransitionEnd={handleTransitionEnd}
        open={open}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="main"
        sx={{
          width: 'calc(100% - 260px)',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          marginTop: '61px',
          px: { xs: 2, sm: 3 },
          py: { xs: 2, sm: 3 },
        }}
      >
        {container && (
          <Box
            sx={{
              px: { xs: 0, sm: 2 },
              position: 'relative',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Breadcrumbs override={ breadcrumbsOverride }/>
            <Outlet context={ { setBreadcrumbsOverride } }/>
            {children}
          </Box>
        )}
        {!container && (
          <Box
            sx={{
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Breadcrumbs override={ breadcrumbsOverride }/>
            <Outlet context={ { setBreadcrumbsOverride } }/>
            {children}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MainLayout;
