import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';
import { removeEmptyValues } from 'utils/objectUtils';

export const getPowerPagesList = createAsyncThunk(
  'powerPages/getPowerPagesList',
  async (
    {
      page = 0,
      currentSearchValue = '',
      isLazyLoad = false,
      perPage = 24,
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const queryParams = {
        page: Number(page),
        per_page: Number(perPage),
        search: currentSearchValue,
        sort: 'id',
        sort_direction: 'desc',
      };

      const response = await axiosInstance.get(`${domainID}/power-pages`, {
        params: removeEmptyValues(queryParams),
        data: {},
      });

      return { ...response.data, isLazyLoad };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getManagePowerPageOptions = createAsyncThunk(
  'powerPages/getManagePowerPageOptions',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const response = await axiosInstance.get(`${domainID}/power-pages/manage`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);


export const getSinglePowerPage = createAsyncThunk(
  'powerPages/getSinglePowerPage',
  async ({ uuid }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const response = await axiosInstance.get(`${domainID}/power-pages/${uuid}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createPowerPages = createAsyncThunk(
  'powerPages/createPowerPages',
  async ({ powerPageData = {} }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const {
        status,
        path,
        name,
        description,
        domainUUID,
        ownerId,
        admins,
        managers,
        settings,
        socialMediaList,
        blocks,
      } = powerPageData;

      const response = await axiosInstance.post(`${domainID}/power-pages`, {
        status,
        path,
        name,
        description,
        domain_uuid: domainUUID,
        owner: ownerId,
        power_page_admins: admins,
        content_manager: managers,
        settings,
        social_medias: socialMediaList,
        blocks,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePowerPage = createAsyncThunk(
  'powerPages/updatePowerPage',
  async ({ powerPageData = {} }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const {
        uuid,
        status,
        path,
        name,
        description,
        domainUUID,
        ownerId,
        admins,
        managers,
        settings,
        socialMediaList,
        blocks,
      } = powerPageData;

      const response = await axiosInstance.patch(`${domainID}/power-pages/${uuid}`, {
        status,
        path,
        name,
        description,
        domain_uuid: domainUUID,
        owner: ownerId,
        power_page_admins: admins,
        content_manager: managers,
        settings,
        social_medias: socialMediaList,
        blocks,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePowerPage = createAsyncThunk(
  'powerPages/deletePowerPage',
  async ({ powerPageId }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const response = await axiosInstance.delete(`/${domainID}/power-pages/${powerPageId}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getBlocks = createAsyncThunk(
  'powerPages/getBlocks',
  async ({ powerPageUUID }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const response = await axiosInstance.get(`/${domainID}/power-page-blocks/${powerPageUUID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteBlock = createAsyncThunk(
  'powerPages/deleteBlock',
  async ({ blockUUID }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const response = await axiosInstance.delete(`/${domainID}/power-page-blocks/${blockUUID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const duplicate = createAsyncThunk(
  'powerPages/duplicate',
  async ({ uuid }, { rejectWithValue, getState }) => {
    try {
      const { auth: { domainID } } = getState();
      const response = await axiosInstance.post(`/${domainID}/power-pages/duplicate/${uuid}`);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createBlock = createAsyncThunk(
  'powerPages/createBlock',
  async ({ blockData, powerPageUUID }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const response = await axiosInstance.post(`/${domainID}/power-page-blocks/${powerPageUUID}`, {
        blocks: {
          link: blockData.link,
          block_settings: blockData.blockSettings,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editBlock = createAsyncThunk(
  'powerPages/editBlock',
  async ({ blockData, blockUUID }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { domainID },
      } = getState();

      const response = await axiosInstance.patch(`/${domainID}/power-page-blocks/${blockUUID}`, {
        blocks: {
          link: blockData.link,
          block_settings: blockData.blockSettings,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

