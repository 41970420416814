
import { getRoles } from '_api/roles';
import { createSlice } from '@reduxjs/toolkit';
import { XHR_STATUS } from 'constants/xhr-status';

const initialState = {
  list: [],
  listRequestStatus: XHR_STATUS.idle,
};

const roles = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: {
    [getRoles.pending]: state => ({
      ...state,
      listRequestStatus: XHR_STATUS.pending,
      
    }),
    [getRoles.fulfilled]: (state, action) => {
      const { data: roles } = action.payload;

      return {
        ...state,
        list: roles,
        listRequestStatus: XHR_STATUS.fulfilled,
      };
    },
    [getRoles.rejected]: state => ({
      ...state,
      listRequestStatus: XHR_STATUS.rejected,
    }),
  },
});

export default roles.reducer;
