import React from 'react';

import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider as ReduxProvider } from 'react-redux';

import 'react-awesome-lightbox/build/style.css';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';
import 'react-phone-input-2/lib/material.css';
import './index.css';


import '_api';
import 'utils/arrayUtils';
import axiosInstance from 'utils/axios';
import getTenant from 'utils/getTenant';

import App from './App';
import { store } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import config from './config';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWrapper from './Auth0ProviderWrapper';

const container = document.getElementById('root');
const root = createRoot(container);

const getOrganizationID = async () => {
  const [topLevelHost] = window.location.hostname.split('.');
  const tenant = getTenant(topLevelHost);

  const response = await axiosInstance.get(`/auth0-organization`, {
    params: {
      tenant,
    },
  });

  return response.data.organization_id;
};

if (['local', 'ci', 'testing', 'test'].includes(config.environment) === false) {
  Sentry.init({
    dsn: 'https://12249dbb8b6a480ea397c23b29575c37@o4504634800209920.ingest.sentry.io/4504766859706368',
    integrations: [new BrowserTracing()],
    environment: config.environment || 'development',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  Sentry.setTag('environment', config.environment);
}

getOrganizationID().then((organizationId) => {
  root.render(
    <ReduxProvider store={store}>
      <ConfigProvider>
        <BrowserRouter>
          <Auth0ProviderWrapper organizationId={organizationId}>
            <App />
          </Auth0ProviderWrapper>
        </BrowserRouter>
      </ConfigProvider>
    </ReduxProvider>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bl.ink/CRA-vitals
reportWebVitals();
