import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getTemplatesList = createAsyncThunk('templates/getTemplatesList', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/templates`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const createTemplate = createAsyncThunk(
  'templates/createTemplate',
  async ({ templateData = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/templates`, templateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editTemplate = createAsyncThunk(
  'templates/editTemplate',
  async ({ templateData = {}, templateUUID = '' }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/templates/${templateUUID}`, templateData);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const removeTemplate = createAsyncThunk(
  'templates/removeTemplate',
  async ({ templateUUID }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/templates/${templateUUID}`);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getTemplateById = createAsyncThunk('templates/getTemplateById', async ({ templateID = '' }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`/templates/${templateID}`);
    response.data.data.groups = response.data.data.groups.map(({ uuid, name }) => (
      { value: uuid, label: name, uuid }
    ));

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});
