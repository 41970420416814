export const optionsPerPage = [
  { value: 24, label: '24' },
  { value: 48, label: '48' },
  { value: 96, label: '96' },
];

export const defaultPagination = {
  page: 1,
  totalPages: 0,
  perPage: optionsPerPage[0].value,
};
