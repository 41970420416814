

import { createSlice } from '@reduxjs/toolkit';
import { XHR_STATUS } from 'constants/xhr-status';
import { getAccountsDomainsPicklist, getDomainGroupsPicklist, getDomainUsersPicklist, getQrTemplatesPicklist, getUsersDomainsPicklist } from '_api/picklists';

const initialState = {
  domainGroups: {
    list: [],
    requestStatus: XHR_STATUS.idle,
  },
  domainUsers: {
    list: [],
    requestStatus: XHR_STATUS.idle,
  },
  domainQrTemplates: {
    list: [],
    requestStatus: XHR_STATUS.idle,
    default: null,
  },
  userDomains: {
    list: [],
    requestStatus: XHR_STATUS.idle,
  },
  accountDomains: {
    list: [],
    requestStatus: XHR_STATUS.idle,
  },
};

const picklists = createSlice({
  name: 'picklists',
  initialState,
  reducers: {},
  extraReducers: {
    // domainGroups
    [getDomainGroupsPicklist.pending]: (state) => {
      state.domainGroups.requestStatus = XHR_STATUS.pending;
    },
    [getDomainGroupsPicklist.fulfilled]: (state, action) => {
      const { data: groups } = action.payload;

      state.domainGroups.requestStatus = XHR_STATUS.fulfilled;
      state.domainGroups.list = groups.sort((a, b) => a.label.localeCompare(b.label));
    },
    [getDomainGroupsPicklist.rejected]: (state) => {
      state.domainGroups.requestStatus = XHR_STATUS.rejected;
    },
    // domainUsers
    [getDomainUsersPicklist.pending]: (state) => {
      state.domainUsers.requestStatus = XHR_STATUS.pending;
    },
    [getDomainUsersPicklist.fulfilled]: (state, action) => {
      const { data: users } = action.payload;

      state.domainUsers.requestStatus = XHR_STATUS.fulfilled;
      state.domainUsers.list = users;
    },
    [getDomainUsersPicklist.rejected]: (state) => {
      state.domainUsers.requestStatus = XHR_STATUS.rejected;
    },
    // domainQrTemplates
    [getQrTemplatesPicklist.pending]: (state) => {
      state.domainQrTemplates.requestStatus = XHR_STATUS.pending;
    },
    [getQrTemplatesPicklist.fulfilled]: (state, action) => {
      const { data: qrTemplates } = action.payload;

      state.domainQrTemplates.requestStatus = XHR_STATUS.fulfilled;
      state.domainQrTemplates.list = qrTemplates;
      state.domainQrTemplates.default = qrTemplates.find(el => el.is_domain_default) || qrTemplates[0];
    },
    [getQrTemplatesPicklist.rejected]: (state) => {
      state.domainQrTemplates.requestStatus = XHR_STATUS.rejected;
    },
    // userDomains
    [getUsersDomainsPicklist.pending]: (state) => {
      state.userDomains.requestStatus = XHR_STATUS.pending;
    },
    [getUsersDomainsPicklist.fulfilled]: (state, action) => {
      const { data: userDomains } = action.payload;

      state.userDomains.requestStatus = XHR_STATUS.fulfilled;
      state.userDomains.list = userDomains;
    },
    [getUsersDomainsPicklist.rejected]: (state) => {
      state.userDomains.requestStatus = XHR_STATUS.rejected;
    },
    // accountDomains
    [getAccountsDomainsPicklist.pending]: (state) => {
      state.accountDomains.requestStatus = XHR_STATUS.pending;
    },
    [getAccountsDomainsPicklist.fulfilled]: (state, action) => {
      const { data: accountDomains } = action.payload;

      state.accountDomains.requestStatus = XHR_STATUS.fulfilled;
      state.accountDomains.list = accountDomains;
    },
    [getAccountsDomainsPicklist.rejected]: (state) => {
      state.accountDomains.requestStatus = XHR_STATUS.rejected;
    },
  },
});

export default picklists.reducer;
