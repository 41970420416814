import moment from 'moment-timezone';

let userTimeZone = 'UTC';

const configureTimeZone = (timeZone) => {
  userTimeZone = timeZone;
};

const momentWithUserTZ = (...args) => moment.tz(...args, userTimeZone);

const getConfiguredTimeZone = () => {
  let abbr = momentWithUserTZ().zoneAbbr();
  if (abbr.includes('+') || abbr.includes('-')) {
    abbr = `GMT ${abbr}`;
  }
  return abbr;
};

export { configureTimeZone, momentWithUserTZ, getConfiguredTimeZone };
