import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { logOut } from '_api/account';

export const useCombinedLogout = (redirectUrl = null) => {
  const dispatch = useDispatch();
  const { logout: auth0Logout } = useAuth0();

  const combinedLogout = () => {
    dispatch(
      logOut(() => {
        localStorage.clear();
        auth0Logout({ logoutParams: { returnTo: redirectUrl ?? window.location.origin } });
      }),
    );
  };

  return combinedLogout;
};
