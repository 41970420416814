import { Link, useLocation, useParams } from 'react-router-dom';

// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { flipObject } from 'utils/objectUtils';
import { capitalize } from 'lodash';
import { Typography } from '@mui/material';
import config from 'config';

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ override = {} }) => {
  const { pathname } = useLocation();
  const params = useParams();
  const paramValues = flipObject(params);
  const segments = pathname.split('/').filter(el => el);

  return (
    <MuiBreadcrumbs sx={{ pb: 3 }} component="nav" aria-label="breadcrumb">
      <Typography
        variant="subtitle1"
        sx={{ textDecoration: 'none' }}
        color="inherit"
        component={Link}
        to={config.defaultPath}
      >
        Home
      </Typography>
      {segments.map((path, index) => {
        const segment = override[paramValues[path]] || path;
        const isLast = index === segments.length - 1;
        const routeTo = `/${segments.slice(0, index + 1).join('/')}`;

        return (
          <Typography
            variant="subtitle1"
            sx={{ textDecoration: 'none' }}
            color={isLast ? 'textPrimary' : 'inherit'}
            component={!isLast ? Link : null}
            key={path}
            to={routeTo}
          >
            {capitalize(segment).replaceAll('-', ' ')
              .replaceAll('_', ' ')}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
