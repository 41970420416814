/* eslint import/no-mutable-exports: 0 */
import axiosInstance from 'utils/axios';

import HTTPRest from './HTTPRest';
import ELink from './ELink/ELink';
import QR from './QR/QR';
import Image from './Image/Image';
import Groups from './Groups/Groups';

let eLinksService;
let httpRestService;
let qrService;
let imageService;
let groupsService;

const initServices = () => {
  httpRestService = new HTTPRest(axiosInstance);
  eLinksService = new ELink(httpRestService);
  qrService = new QR(httpRestService);
  imageService = new Image(httpRestService);
  groupsService = new Groups(httpRestService);
};

export { initServices, eLinksService, qrService, imageService, groupsService };
