import { useEffect, useMemo, useCallback } from 'react';
// material-ui
import { Autocomplete, Box, FormControl, IconButton, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { dispatch } from 'store';
import { getFeatures } from '_api/features';
import { setUserDomain } from 'store/reducers/auth';
import StarOutlined from '@ant-design/icons/StarOutlined';
import { UserLayer } from 'pages/admin/constant';
import { resetFilters } from 'store/reducers/filters';
import { authSelector } from 'selectors/auth.js';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //
const DomainSelection = () => {
  const dmID = localStorage.getItem('domainID');
  const {
    domainID,
    user: { domains: domainsList, uuid: userID },
    role,
    roles,
    userRoleType,
  } = useSelector(authSelector || {});

  const domainListOptions = useMemo(
    () =>
      domainsList?.map(({ uuid: id, name: label, id: userRoleDomainID }) => ({
        id,
        label,
        userRoleDomainID,
        selected: domainID === id,
      })) ?? [],
    [domainID, domainsList],
  );
  const selectedValue = useMemo(
    () => domainListOptions.find(option => option.selected) || domainListOptions[0],
    [domainListOptions],
  );

  const setDomainID = useCallback(
    (event, value) => {
      if (!value) {
        return;
      }

      const { id: domainID, userRoleDomainID } = value;

      localStorage.setItem('domainID', domainID);
      localStorage.setItem('userRoleDomainID', userRoleDomainID);

      const currActiveDomainRole = userRoleType === UserLayer.Account ?
        roles.find(rl => rl.code === role.code) :
        domainsList.find(domain => domain.uuid === domainID).user_domain_roles.sort((a, b) => a.id - b.id)[0];

      dispatch(getFeatures());
      dispatch(resetFilters());
      dispatch(setUserDomain({ domainID, userRoleDomainID, currActiveDomainRole }));

      window.addEventListener('beforeunload', (event) => {
        event.preventDefault();
        return null;
      });
    },
    [roles, userRoleType, role],
  );

  useEffect(() => {
    if (userID) {
      localStorage.setItem('domainName', window.location.origin);
    }
  }, [userID]);

  useEffect(() => {
    if (!domainID && Boolean(domainListOptions.length)) {
      if (dmID && domainListOptions.length) {
        const { id, userRoleDomainID } = domainListOptions.find(dm => dm.id === dmID) || domainListOptions[0];
        setDomainID(null, { id, userRoleDomainID });
        return;
      }

      const [{ id, userRoleDomainID }] = domainListOptions;
      setDomainID(null, { id, userRoleDomainID });
    }
  }, [dmID, domainID, domainListOptions, setDomainID]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <FormControl sx={{ width: 300, height: '100%', justifyContent: 'center' }}>
        <Autocomplete
          forcePopupIcon={false}
          onChange={setDomainID}
          options={domainListOptions}
          blurOnSelect
          value={selectedValue}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            '& .MuiInputBase-input': {
              WebkitTextFillColor: '#453F91 !important',
              fontWeight: 600,
            },
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder="Current Domain"
              InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
              sx={{ '& .MuiInputBase-input': { fontSize: '20px' } }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                  >
                    <StarOutlined />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default DomainSelection;
