import { lazy } from 'react';
import { Navigate } from 'react-router';

import MainLayout from 'layout/MainLayout';
import loadable from 'components/Loadable';
import AuthGuard from 'middleware/AuthGuard';
import Auth0Logout from 'pages/auth/auth0Logout';

const LinkAnalyticPage = loadable(lazy(() => import('pages/LinkAnalytic/LinkAnalyticPage')));
const DashboardDefault = loadable(lazy(() => import('pages/dashboard/default')));
const MaintenanceError = loadable(lazy(() => import('pages/maintenance/404')));
const UserProfile = loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabDomainInformation = loadable(lazy(() => import('sections/apps/profiles/user/DomainInformation')));
const AccountTabPassword = loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
const GroupsPage = loadable(lazy(() => import('pages/Groups')));

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="/links" />,
        },
        {
          path: 'account',
          element: <UserProfile />,
          children: [
            {
              path: '',
              element: <UserTabPersonal />,
            },
            {
              path: 'domain',
              element: <UserTabDomainInformation />,
            },
            {
              path: 'password',
              element: <AccountTabPassword />,
            },
          ],
        },
        {
          path: 'groups',
          element: <GroupsPage />,
        },
        {
          path: 'links',
          element: <DashboardDefault />,
        },
        {
          path: '/links/:linkId',
          element: <LinkAnalyticPage />,
        },
      ],
    },
    {
      path: 'logout',
      element: <Auth0Logout />,
    },
    // catch all //
    {
      path: '*',
      element: <MaintenanceError />,
    },
  ],
};

export default MainRoutes;
