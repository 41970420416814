
import { useNavigate } from 'react-router-dom';
import { useCombinedLogout } from 'hooks/useCombinedLogout';
import { Backdrop, CircularProgress } from '@mui/material';
import { useEffect } from 'react';

const Auth0Logout = () => {
  const logout = useCombinedLogout();
  const navigate = useNavigate();
  
  useEffect(() => {
    const performLogout = async () => {
      await logout(window.location.origin);
    };
    performLogout();
  }, [logout, navigate]);

  return <Backdrop
    open
    sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
    data-testid="loading-overlay"
  >
    <CircularProgress color="primary" size="5rem" />
  </Backdrop>;
};

export default Auth0Logout;
