import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import * as React from 'react';
import PropTypes from 'prop-types';
import { isValid } from 'date-fns';
import { momentWithUserTZ } from 'utils/momentWithTz.js';

const GridFilterDateInput = (props) => {
  const { item, showTime, applyValue, apiRef } = props;

  const Component = showTime ? DateTimePicker : DatePicker;

  const handleFilterChange = (selectedDate) => {
    if (!isValid(selectedDate)) {
      return;
    }

    applyValue({
      ...item,
      value: new Date(selectedDate),
    });
  };

  return (
    <Component
      label={apiRef.current.getLocaleText('filterPanelInputLabel')}
      defaultValue={item.value}
      slotProps={{
        textField: {
          variant: 'standard',
          sx: {
            '& .MuiButtonBase-root': {
              marginRight: -1,
            },
          },
        },
      }}
      onChange={handleFilterChange}
    />
  );
};

GridFilterDateInput.propTypes = {
  apiRef: PropTypes.shape({
    current: PropTypes.object.isRequired,
  }).isRequired,
  applyValue: PropTypes.func.isRequired,
  item: PropTypes.shape({
    /**
     * The column from which we want to filter the rows.
     */
    columnField: PropTypes.string.isRequired,
    /**
     * Must be unique.
     * Only useful when the model contains several items.
     */
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /**
     * The name of the operator we want to apply.
     * Will become required on `@mui/x-data-grid@6.X`.
     */
    operatorValue: PropTypes.string,
    /**
     * The filtering value.
     * The operator filtering function will decide for each row if the row values is correct compared to this value.
     */
    value: PropTypes.any,
  }).isRequired,
  showTime: PropTypes.bool,
};

export const buildApplyDateFilterFn = (filterItem, compareFn, showTime = false) => {
  if (!filterItem.value) {
    return null;
  }

  const filterValue = showTime ? new Date(filterItem.value) : new Date(filterItem.value).setHours(0);
  const filterValueMs = new Date(filterValue).getTime();

  return ({ value }) => {
    if (!value) {
      return false;
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = new Date(value);
    dateCopy.setHours(showTime ? value.getHours() : 0, showTime ? value.getMinutes() : 0, 0, 0);

    const cellValueMs = dateCopy.getTime();

    return compareFn(cellValueMs, filterValueMs);
  };
};

export const getDateFilterOperators = (showTime = false) => [
  {
    value: 'is',
    getApplyFilterFn: filterItem => buildApplyDateFilterFn(filterItem, (value1, value2) => value1 === value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'not',
    getApplyFilterFn: filterItem => buildApplyDateFilterFn(filterItem, (value1, value2) => value1 !== value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'after',
    getApplyFilterFn: filterItem => buildApplyDateFilterFn(filterItem, (value1, value2) => value1 > value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'onOrAfter',
    getApplyFilterFn: filterItem => buildApplyDateFilterFn(filterItem, (value1, value2) => value1 >= value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'before',
    getApplyFilterFn: filterItem => buildApplyDateFilterFn(filterItem, (value1, value2) => value1 < value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'onOrBefore',
    getApplyFilterFn: filterItem => buildApplyDateFilterFn(filterItem, (value1, value2) => value1 <= value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    label: 'Today',
    value: 'today',
    getApplyFilterFn:
      () =>
        ({ value }) =>
          momentWithUserTZ(value)
            .isSame(momentWithUserTZ(), 'day'),
    requiresFilterValue: false,
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    getApplyFilterFn:
      () =>
        ({ value }) =>
          momentWithUserTZ(value)
            .isBetween(momentWithUserTZ()
              .subtract(1, 'd'), momentWithUserTZ(value), 'day'),
    requiresFilterValue: false,
  },
  {
    label: 'Last 30 Days',
    value: 'last30Days',
    getApplyFilterFn:
      () =>
        ({ value }) =>
          momentWithUserTZ(value)
            .isBetween(momentWithUserTZ()
              .subtract(30, 'd'), momentWithUserTZ(), 'day'),
    requiresFilterValue: false,
  },
  {
    label: 'This Month',
    value: 'thisMonth',
    getApplyFilterFn:
      () =>
        ({ value }) =>
          momentWithUserTZ(value)
            .isSame(momentWithUserTZ(), 'month'),
    requiresFilterValue: false,
  },
];

export const operatorsWithoutValue = ['isEmpty', 'isNotEmpty', 'today', 'yesterday', 'last30Days', 'thisMonth'];

export const removeCheckboxFromColumns = arr => arr.filter(({ field }) => field !== '__check__');

export const formatFilters = (filters, columns) => {
  const types = columns
    .map((column) => {
      const type = {};
      if (column?.field) {
        type[column?.field] = column?.type;
        return type;
      }
    })
    .filter(type => type !== undefined);

  return (
    filters?.items
      .map((filter) => {
        const type = types.find(type => type[filter.columnField])?.[filter.columnField] || 'string';
        let { value } = filter;

        if (type === 'date' && !operatorsWithoutValue.includes(filter.operatorValue)) {
          value =  momentWithUserTZ(filter.value)
            .format('YYYY-MM-DD');
        }

        return {
          column: filter.columnField,
          operator: filter.operatorValue,
          value,
          type,
        };
      })
      .filter(filter => (
        (filter.value !== null && filter.value !== undefined && filter.value !== '') ||
        operatorsWithoutValue.includes(filter.operator)
      )) || []
  );
};
