import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getRoles = createAsyncThunk('roles/getRoles', async (_, { rejectWithValue }) => {
  try {
    const { data: responseData } = await axiosInstance.get('/roles');

    return responseData;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});


