import realAxiosInstance from 'utils/axios';
import mockedAxiosInstance from 'mock';

const getCustomBaseQuery = isMock => async ({ url, method, data, params }) => {
  const axiosInstance = isMock ? mockedAxiosInstance : realAxiosInstance;

  try {
    const result = await axiosInstance({
      url,
      method,
      data,
      params,
    });

    return { data: result.data };
  } catch (error) {
    return {
      error: { status: error.response?.status, data: error.response?.data },
    };
  }
};

export default getCustomBaseQuery;
