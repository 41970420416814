export default [
  {
    endpoint: '/attributes',
    method: 'GET',
    response: {
      status: 200,
      body: {
        data: [
          {
            active: true,
            uuid: 'b31d4350-d085-11ee-8c9c-1250d2fb2707',
            name: 'Some name',
            code: 'code',
            type: 'CUSTOM',
            field_type: 'SINGLE_LINE_TEXT',
            additional_notes: 'notes', // FIXME
            description: 'description',
            order: 1,
            templates: [], // FIXME
            created_at: '2024-02-21T06:51:54.000000Z', // uses on Attributes table
            updated_at: '2024-02-21T06:51:54.000000Z', // uses on Attributes table
            author: 'Pavlo', // uses on Attributes table
          },
        ],
      },
    },
  },
];
