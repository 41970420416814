import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getDomainGroupsPicklist = createAsyncThunk(
  'picklist/getDomainGroupsPicklist',
  async ({ uuid, params = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `picklist/domains/${uuid}/groups`,
        {
          params,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getDomainUsersPicklist = createAsyncThunk(
  'picklist/getDomainUsersPicklist',
  async ({ uuid, params = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `picklist/domains/${uuid}/users`,
        {
          params,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getQrTemplatesPicklist = createAsyncThunk(
  'picklist/getDomainQrTemplatesPicklist',
  async ({ uuid, params = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `picklist/domains/${uuid}/qr_templates`,
        {
          params,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAccountsDomainsPicklist = createAsyncThunk(
  'picklist/getAccountsDomainsPicklist',
  async ({ accountUuid, params = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `picklist/accounts/${accountUuid}/domains`,
        {
          params,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);


export const getUsersDomainsPicklist = createAsyncThunk(
  'picklist/getUsersDomainsPicklist',
  async ({ userUuid, params = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `picklist/users/${userUuid}/domains`,
        {
          params,
        },
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);


