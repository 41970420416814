import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'utils/axios';

export const getUserAllRoles = createAsyncThunk('admin/getUserAllRoles', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get('/roles');

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const getUsersList = createAsyncThunk(
  'admin/getUsersList',
  async ({ userRoleType }, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { role: activeRoleDomain },
      } = getState();

      const { data: { meta: { total } } } = await axiosInstance.get(`/users`);
      const numberOfRequests = Math.ceil(total / 100);

      const promises = [];
      for (let i = 0; i < numberOfRequests; i++) {
        promises.push(axiosInstance.get(`/users?per_page=100&page=${i + 1}`));
      }

      const response = await Promise.all(promises);
      const users = response.reduce((memo, current) => [...memo, current.data.data], []).flatMap(item => item);

      return { data: { data: users, meta: response[0].data.meta }, userType: userRoleType, activeRoleDomain };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const createUser = createAsyncThunk('admin/createUser', async ({ userData = {} }, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/users', userData);
    return response.data;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const deleteUsers = createAsyncThunk(
  'admin/deleteUsers',
  async ({ data = {} }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/users/delete`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
