// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

export default function InputLabel(theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        // root: {
        //   color: theme.palette.grey[600]
        // },
        // outlined: {
        //   '&.MuiInputLabel-sizeSmall': {
        //     lineHeight: '1em'
        //   },
        //   '&.MuiInputLabel-root:not(&.MuiInputLabel-shrink)': {
        //     lineHeight: '24px',
        //     height: '100%',
        //     padding: '10px',
        //     transform: 'none'
        //   },
        //   '&.MuiInputLabel-shrink': {
        //     background: 'transparent',
        //     padding: '0 8px',
        //     marginLeft: -6,
        //     lineHeight: '1.4375em'
        //   }
        // }
      }
    }
  };
}
